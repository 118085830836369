/*
 * Base structure
 */

body {
  padding-top: 54px;
}

/*
 * Typography
 */

h1 {
  margin-bottom: 20px;
  padding-bottom: 9px;
  border-bottom: 1px solid #eee;
}

h2 {
  font-weight: 100;
  font-size: 1.7rem;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 44px;
  bottom: 0;
  left: 0;
  z-index: 1000;
  padding: 20px;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}
/* Sidebar navigation */
.sidebar {
  padding-left: 0;
  padding-right: 0;
}

.sidebar .nav {
  margin-bottom: 20px;
}

.sidebar .nav-item {
  width: 100%;
}

.sidebar .nav-item + .nav-item {
  margin-left: 0;
}

.sidebar .nav-link {
  border-radius: 0;
}

.sidebar li li a {
  color: $link-lighter-color;
}

.sidebar h2 {
  margin-left: $spacer;
  color: black;
}

ul.social {
  display: inline;

  li, .nav-link {
    display: inline;
  }

  .nav-item:nth-child(2) .nav-link {
    padding-left: 0;
  }
}

main {
  .nav-item:nth-child(1) .nav-link {
    padding-left: 0;
  }
}

.sidebar {
  .nav-sub {
    list-style: outside none none;
    padding-left: .7em;
  }
}

.sidebar .copyright {
  font-size: .8rem;
  color: $link-lighter-color;
  margin-top: 2rem;
}

nav .copyright {
  font-size: .8rem;
}


/*
 * Nav
 */

.navbar-brand {
    font-weight: 100;

    .photography {
      color: $gray;
      display: none;
    }
}
@media (min-width: 396px)
{
  .navbar-brand {
    .photography {
      display: inline;
    }
  }
}
@media (min-width: 576px)
{
  .navbar-brand {
    font-size: 1.5rem;

    .photography {
      color: $link-lighter-color;
    }
  }
}

.navbar-toggler {
  z-index: 101;
}

nav {
  background-color: $gray-dark;
}
@media (min-width: 576px) {
  nav {
    background-color: white;
    color: white;

    border-bottom: 1px solid $gray-lighter;
  }
  .navbar-inverse .navbar-brand {
    color: $link-color;
    &:hover {
      color: $link-color;
    }
  }
}


/*
 * MENU mobile
 */

.dropdown-menu {
    background-color: transparent;
    border: 0 none;
    margin: 0.125rem 0 0;
    padding: 0.5rem 0;
}
.dropdown-item {
    background: transparent;
    border: 0 none;
    clear: both;
    color: $gray-light;
}

.dropdown-item.active, .dropdown-item:active {
    background-color: $gray-light;
}

/*
* Galery
 */

div.gallery {
    padding-left: $spacer / 4;
    padding-right: $spacer / 4;
    margin-bottom: $spacer / 2;
}

.ekko-lightbox {
  .modal-dialog {
    margin: $spacer auto;
  }
}
.ekko-lightbox.modal {
  background-color: white;

  .modal-footer {
    border-top: none;
    padding: $spacer / 4 $spacer 0 $spacer;
    // justify-content: flex-start;
  }
  .modal-body {
    padding: 0 $spacer;
  }
  .modal-content {
    border: none;
  }

  &.fade {
    transition: none;
  }
}
.modal-backdrop {
  background-color: #FFF;
}
.modal-backdrop.show {
  opacity: 1;
  &.fade {
    transition: none;
  }
}

/*
* Carousel
 */

html,
body,
body > .container-fluid,
body > .container-fluid > .row,
main {
    height: 100%;
}

.carousel,
.carousel-item {
    height: 100%;
    width: 100%
}

.carousel-inner {
  height: 100%;
}

.carousel-item {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

// Carousel fade transition
.carousel-fade {
  .carousel-item {
    display: block;
    position: absolute;
    opacity: 0;
    transition: opacity .75s ease-in-out;

    &.active {
      opacity: 1;
    }
  }
}


/* Lightbox */
.ekko-lightbox-nav-overlay a span {
  opacity: 0;
}

.carousel-control-prev,
.carousel-control-next {
   span {
    opacity: 0;
  }
}
